<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-md-5">
        <div class="card siteCard mt-3">

          <div class="p-3">
            <div class="text-center py-3 space-y-3">
              <img src="/images/party-popper.png" class="mb-3 h-36 mx-auto" alt="popper" />

              <h3 class="text-dark text-3xl text-black font-bold">Subscription Completed!</h3>
              <p class="text-dark text-sm">
                You have successfully subscribed to our service. You can now
                start using our service.
              </p>
            </div>
            <div class="text-center py-4">
              <router-link to="/app" class="btn btn-primary btn-site px-5">
                <span>Continue to dashboard</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.initiateConfetti()
    this.getUserProfile();
  },
  methods: {
    initiateConfetti() {

      this.$confetti.start({
        particles: [
          {
            type: 'heart',
          }
        ],
        defaultColors: [
          'red',
          'pink',
          '#ba0000',
        ],
      });

      setTimeout(() => {
        this.$confetti.stop();
      }, 6000);
    },
    getUserProfile() {
      let fetch = {
        path: `/profile`,
      };
      this.loading = true;
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          const { data } = resp;
          let { user } = data.data;

          user = this.CryptoJS.AES.encrypt(
            JSON.stringify(user),
            this.$passPhrase
          ).toString();

          localStorage.setItem("user", user);
        })
        .catch((err) => { });
    },
  },
};
</script>
<style lang="scss" scoped>
.heading-txt {
  font-size: 3em;
  line-height: 1;
  font-weight: 700;
  color: #000;
}

.siteCard {
  position: relative;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 400px;
}
</style>
